import React, { useState } from 'react';
import OverlaySection from './OverlaySection';
import html2canvas from 'html2canvas';
import './App.css';
import mainImage from './fuckercat/default.png';
import oculos1 from "./fuckercat/oculos1.png";
import oculos2 from "./fuckercat/oculos2.png";
import oculos3 from "./fuckercat/oculos3.png";
import oculos4 from "./fuckercat/oculos4.png";
import conjunto1 from "./fuckercat/conjunto1.png";
import conjunto2 from "./fuckercat/conjunto2.png";
import conjunto3 from "./fuckercat/conjunto3.png";
import conjunto4 from "./fuckercat/conjunto4.png";
import bg1 from "./fuckercat/1.webp";
import bg2 from "./fuckercat/2.webp";
import bg3 from "./fuckercat/3.webp";
import bg4 from "./fuckercat/4.webp";

function Token({ tokenName }) {
  const [overlays, setOverlays] = useState({
    glasses: null,
    outfit: null,
    background: null,
  });

  const imageOptions = {
    glasses: [oculos1, oculos2, oculos3, oculos4],
    outfit: [conjunto1, conjunto2, conjunto3, conjunto4],
    background: [bg1, bg2, bg3, bg4],
  };

  const handleRandomize = () => {
    const randomOverlays = {};
    Object.keys(imageOptions).forEach((key) => {
      const images = imageOptions[key];
      const randomIndex = Math.floor(Math.random() * images.length);
      randomOverlays[key] = images[randomIndex];
    });
    setOverlays(randomOverlays);
  };

  const handleDownload = async () => {
    const filename = 'custom_cat_meme.png';
    const element = document.getElementById('image-container');
    const canvas = await html2canvas(element);
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = filename;
    link.click();
  };

  const handleRemoveOverlay = (key) => {
    setOverlays({ ...overlays, [key]: null });
  };

  return (
    <div className="app">
      <header className="header">
        <h1>🐱 {tokenName} Meme Creator 🐱</h1>
      </header>

      <main className="main-content">
        <div className="env-l">
          <div id="image-container" className="image-container">
            {overlays.outfit !== conjunto4 && (
              <img src={mainImage} alt="Main Reference" className="main-image" />
            )}
            
            {Object.keys(overlays).map((key, index) => {
              if (overlays[key]) {
                let zIndex;
                if (key === 'background') zIndex = 0;
                else if (key === 'outfit') zIndex = 2;
                else if (key === 'glasses') zIndex = 3;
                else zIndex = 1;

                return (
                  <img
                    key={index}
                    src={overlays[key]}
                    alt={`${key} Overlay`}
                    className="overlay-image"
                    style={{ zIndex }}
                  />
                );
              }
              return null;
            })}
          </div>

          <div className="controls">
            <button className="control-button" onClick={handleRandomize}>Randomize</button>
            <button className="control-button" onClick={handleDownload}>Download</button>
          </div>
        </div>
        
        <div className="env-r">
          <div className="overlay-sections">
            {Object.keys(imageOptions).map((key) => (
              <OverlaySection
                key={key}
                section={key}
                options={imageOptions[key]}
                setOverlay={(img) => setOverlays({ ...overlays, [key]: img })}
                currentOverlay={overlays[key]}
                onRemove={() => handleRemoveOverlay(key)}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Token;
