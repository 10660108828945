import React from 'react';

function OverlaySection({ section, options, setOverlay, currentOverlay, onRemove }) {
  return (
    <div className="overlay-section">
      <h3>{section.charAt(0).toUpperCase() + section.slice(1)}</h3>
      <div className="overlay-options">
        {options.map((option, index) => (
          <div key={index} className="option-container">
            <img
              src={option}
              alt={`${section} Option ${index + 1}`}
              onClick={() => setOverlay(option)}
              className={`thumbnail ${currentOverlay === option ? 'selected' : ''}`}
            />
            {currentOverlay === option && (
              <button className="remove-button" onClick={() => onRemove(section)}>
                X
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OverlaySection;
