import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Token from './Token';
import logo from "./logoo.png";
import m1 from "./fuckercat/memes/1.png";
import m2 from "./fuckercat/memes/2.png";
import m3 from "./fuckercat/memes/3.png";
import m4 from "./fuckercat/memes/4.png";
import m5 from "./fuckercat/memes/5.png";
import m6 from "./fuckercat/memes/6.png";

const accessKey = 'A1qQaAA9kdfnn4Mmn44bpoieIYHKkdghFKUD1978563llakLLLKdfslphgarcorc3haeogmmMNn243wf';

function App() {
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://interca.onrender.com/api/purchaseData', {
          headers: {
            'x-access-key': accessKey,
          },
        });
        setData(response.data);
      } catch (error) {
        setError('Error fetching data. Please check the access key and try again.');
      }
    };

    fetchData();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(data.tokenCA || "No CA available");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="App">
      <div className="sec1">
        <div className="left">
          <h1>{data.tokenName || "Token Name"}</h1>
          <p>Customize your {data.tokenName} with the coolest styles from pump.fun! Download your favorite styles and share with friends. Post on X and tag us for a chance to appear on our official account. Join our Telegram channel to be part of our vibrant community!</p>
          <div className="socials">
            <a className='social' href={data.twitterLink}>X/Twitter</a>
            <a className='social' href={data.telegramLink}>Telegram</a>
          </div>
          <a className='buy' href={data.link}>BUY NOW</a>
          <div className="tokenCa" onClick={handleCopy}>
            CA: {data.tokenCA} {copied && <span>(Copied!)</span>}
          </div>
        </div>
        <div className="right">
          <img src={logo} alt="Logo" />
        </div>
      </div>

      <div className="line">
        {Array(8).fill(<div>{data.tokenName || "Token Name"}</div>)}
      </div>

      <div className="sec2">
        <Token tokenName={data.tokenName} />
      </div>

      <div className="line">
        {Array(8).fill(<div>{data.tokenName || "Token Name"}</div>)}
      </div>

      <div className="sec3">
        <div className="header">
          <h1>🐱 Memes 🐱</h1>
        </div>
        <div className="env-memes">
          <div className="fl">
            <img src={m1} alt="Meme 1" />
            <img src={m2} alt="Meme 2" />
            <img src={m3} alt="Meme 3" />
          </div>
          <div className="ll">
            <img src={m4} alt="Meme 4" />
            <img src={m5} alt="Meme 5" />
            <img src={m6} alt="Meme 6" />
          </div>
        </div>
      </div>
      <div className="footer">
        Follow us on social media!
      </div>
    </div>
  );
}

export default App;
